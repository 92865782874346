.App-logo {
	height: 40vmin;
	pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}
@media all {
	.page-break {
		display: none;
	}
}
@media print {
	@page {
		size: landscape;
	}
}

@media print {
	html,
	body {
		height: initial !important;
		overflow: initial !important;
		-webkit-print-color-adjust: exact;
	}
}

@media print {
	.page-break {
		margin-top: 2mm;
		display: block;
		page-break-before: auto;
		page-break-after: always;
	}
}

@page {
	size: auto;
	margin: 2mm;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}
.App-link {
	color: #61dafb;
}
@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
body {
	background-color: #ebebeb;
	font-family: "Oswald", "Helvetica Neue", Arial, Helvetica, sans-serif;
	font-size: 16px;
	font-weight: 300;
}
.login-form {
	font-size: 14px;
}
.forgot-password {
	color: #ff5722;
}
.logo-new {
	width: 75%;
}
.logo-login {
	width: 202px;
	height: 116px;
	position: relative;
}
.fa-select-icons {
	color: black;
}
.fa-select-icons:hover {
	color: orange;
}
#dropdownMenuLink:hover {
	cursor: pointer;
}
#sidebar-wrapper {
	min-height: 100vh;
	margin-left: -15rem;
	-webkit-transition: margin 0.25s ease-out;
	-moz-transition: margin 0.25s ease-out;
	-o-transition: margin 0.25s ease-out;
	transition: margin 0.25s ease-out;
	background: linear-gradient(to bottom, rgb(41, 69, 95), rgb(29, 45, 68));
}
#sidebar-wrapper .sidebar-heading {
	padding: 0.875rem;
	width: 13.5rem;
}
#sidebar-wrapper .list-group {
	width: 100%;
}
#page-content-wrapper {
	min-width: 100vw;
}
#wrapper.toggled #sidebar-wrapper {
	margin-left: 0;
}
@media (min-width: 768px) {
	#sidebar-wrapper {
		margin-left: 0;
	}
	#page-content-wrapper {
		min-width: 0;
		width: 100%;
	}
	#wrapper.toggled #sidebar-wrapper {
		margin-left: -15rem;
	}
}
#eyeButton {
	color: black;
}
#eyeButton:hover {
	color: orange;
}
#editButton {
	color: black;
}
#editButton:hover {
	color: orange;
}
.nav-item {
	color: white;
	opacity: 0.5;
	height: 42px;
	padding-top: 0.5rem;
	cursor: pointer;
}
.nav-item:hover {
	color: white;
	opacity: 1;
	background: rgb(41, 69, 95);
	cursor: pointer;
}
.selected-nav-item {
	color: white;
	opacity: 0.9;
	height: 42px;
	padding-top: 0.5rem;
	cursor: pointer;
}
.selected-nav-item:hover {
	color: white;
	opacity: 0.9;
	background: rgb(41, 69, 95);
	cursor: pointer;
}
.type-select {
	width: 15%;
	margin-left: 2%;
	visibility: visible;
}
.target-select {
	width: 15%;
	margin-left: 2%;
	visibility: visible;
}
.envelope-select {
	width: 15%;
	margin-left: 2%;
	visibility: visible;
}
.shipping-company-select {
	width: 15%;
	margin-left: 2%;
	visibility: visible;
}
.source-select {
	width: 15%;
	margin-left: 2%;
	visibility: visible;
}
.selector-names {
	width: 15%;
	margin-left: 2%;
}
.selector-names-hidden {
	width: 15%;
	margin-left: 2%;
	visibility: hidden;
}
.toast {
	position: absolute;
	top: 9%;
	right: 0;
}
.loader {
	margin-top: 25%;
	margin-bottom: 25%;
}
.pagination {
	margin-top: 10px !important;
}
.pagination button {
	background-color: #022855 !important;
	color: white !important;
	margin-right: 0.5vw !important;
	font-size: 1vw !important;
}
.pagination button:disabled {
	background-color: #707070 !important;
}
.pagination input {
	width: 2vw !important;
	margin-right: 0.5vw !important;
	text-align: center;
	font-size: 1vw !important;
}
.pagination div {
	margin-right: 0.5vw !important;
}
.pagination-text {
	margin-top: 0.5vw !important;
	font-size: 1vw !important;
	color: #81878b !important;
}
.badge-roles {
	width: 40%;
	margin-left: 3%;
	text-align: center;
}
.removeButton {
	float: right;
	cursor: pointer;
}
.error-message {
	color: red;
}
.page-name {
	font-size: 1.5vw;
}
.card-text {
	font-size: 1.5vw;
}
.card-count {
	font-size: 3vw;
}
.dropdown-menu.dropdown-menu-wide {
	min-width: 100%;
	text-align: center;
}
.btn-group {
	width: 100%;
}

/**
 * Main wrapper
 */
.select-search {
	width: 100%;
	position: relative;
	box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
	box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
	position: relative;
	z-index: 1;
}

.select-search__value::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: calc(50% - 9px);
	right: 19px;
	width: 11px;
	height: 11px;
}

/**
* Input
*/
.select-search__input {
	display: block;
	height: 36px;
	width: 100%;
	padding: 0 16px;
	background: #fff;
	border: 1px solid transparent;
	box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	outline: none;
	font-family: "Noto Sans", sans-serif;
	font-size: 14px;
	text-align: left;
	text-overflow: ellipsis;
	line-height: 36px;
	-webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
	-webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
	cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
	background: #fff;
	box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
	list-style: none;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
	border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option {
	display: block;
	height: 36px;
	width: 100%;
	padding: 0 16px;
	background: #fff;
	border: none;
	outline: none;
	font-family: "Noto Sans", sans-serif;
	font-size: 14px;
	text-align: left;
	cursor: pointer;
}

.select-search--multiple .select-search__option {
	height: 48px;
}

.select-search__option.is-selected {
	background: #2fcc8b;
	color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
	background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
	background: #2eb378;
	color: #fff;
}

/**
* Group
*/
.select-search__group-header {
	font-size: 10px;
	text-transform: uppercase;
	background: #eee;
	padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
	opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
	background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
	cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
	border-radius: 3px;
	overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
	.select-search__value::after {
	transform: rotate(45deg);
	border-right: 1px solid #000;
	border-bottom: 1px solid #000;
	pointer-events: none;
}

.select-search--multiple .select-search__input {
	cursor: initial;
}

.select-search--multiple .select-search__input {
	border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
	cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
	border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
	position: absolute;
	z-index: 2;
	top: 44px;
	right: 0;
	left: 0;
	border-radius: 3px;
	overflow: auto;
	max-height: 360px;
}

.select-search--multiple .select-search__select {
	position: relative;
	overflow: auto;
	max-height: 260px;
	border-top: 1px solid #eee;
	border-radius: 0 0 3px 3px;
}
